import React, { Component } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Button
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import cx from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    submit: {
      width: "100%",
      marginTop: theme.spacing(4),
      textTransform: "capitalize",
      height: 50,
      fontSize: 18
    },
    root: {}
  });

interface Props extends WithStyles<typeof styles> {
  label?: string;
}

class SubmitButton extends Component<Props & ButtonProps> {
  render() {
    const { classes, label, className, ...props } = this.props;

    return (
      <div className={classes.root}>
        <Button
          {...props}
          className={cx(classes.submit, className)}
          variant="contained"
          color="primary"
          type="submit"
        >
          {label ? label : "Continue"}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(SubmitButton);
