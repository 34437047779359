import React, { Component } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Theme,
  Typography
} from "@material-ui/core";
import logo from "../../images/certane-logo-small.png";
import Footer from "./Footer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 400,
      textAlign: "center"
    },
    subtitle: {
      margin: theme.spacing(2),
      color: theme.palette.grey[700]
    },
    title: {
      fontSize: 36,
      fontWeight: 100,
      color: theme.palette.grey[900]
    },
    logo: {
      width: 84,
      marginBottom: theme.spacing(2)
    }
  });

interface Props extends WithStyles<typeof styles> {
  title: string;
  subtitle: string;
}

export class Onboarding extends Component<Props> {
  render() {
    const { children, classes, title, subtitle } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <img src={logo} alt="" className={classes.logo} />
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {subtitle}
          </Typography>
          {children}
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Onboarding);
